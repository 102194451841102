// extracted by mini-css-extract-plugin
export var mainContainer = "NavBar-module--mainContainer--2EYxT";
export var mainContainerOpened = "NavBar-module--mainContainerOpened--15SQZ";
export var mainDiv = "NavBar-module--mainDiv--lEKn5";
export var topMainDiv = "NavBar-module--topMainDiv--3Nxfs";
export var mainDesktopUl = "NavBar-module--mainDesktopUl--Q-IcE";
export var mainMobileUl = "NavBar-module--mainMobileUl--3oaFc";
export var mainOptionsDiv = "NavBar-module--mainOptionsDiv--2YI2f";
export var optionLi = "NavBar-module--optionLi--2EyE7";
export var optionLink = "NavBar-module--optionLink--3w2pj";
export var optionSvgLi = "NavBar-module--optionSvgLi--1UofR";
export var logoDiv = "NavBar-module--logoDiv--13UHz";
export var logoA = "NavBar-module--logoA--16yBf";
export var logoImg = "NavBar-module--logoImg--2_P6f";
export var recetasDropdown = "NavBar-module--recetasDropdown--1KcT_";
export var recetasDropdownContent = "NavBar-module--recetasDropdownContent--D2_OJ";
export var linkBold = "NavBar-module--linkBold--fhxeI";
export var searchIconDiv = "NavBar-module--searchIconDiv--5sBGs";
export var searchIconImg = "NavBar-module--searchIconImg--fmCDb";
export var searchMainDiv = "NavBar-module--searchMainDiv--2gLVx";
export var searchDiv = "NavBar-module--searchDiv--We9Lq";
export var searchInput = "NavBar-module--searchInput--2RIbo";
export var searchButton = "NavBar-module--searchButton--YC24G";
export var searchGlass = "NavBar-module--searchGlass--14oZy";
export var searchForm = "NavBar-module--searchForm--13e8u";
export var optionLoginLink = "NavBar-module--optionLoginLink--1Lggf";
export var optionCoursesLink = "NavBar-module--optionCoursesLink--2J__I";
export var optionBurgerIconSvg = "NavBar-module--optionBurgerIconSvg--1TxAW";
export var optionBurgerIconPath = "NavBar-module--optionBurgerIconPath--pTyus";
export var burgerMenuMainDiv = "NavBar-module--burgerMenuMainDiv--3Ga_V";
export var burgerLogoImg = "NavBar-module--burgerLogoImg--2zTx-";
export var burgerMenuFirstLineDiv = "NavBar-module--burgerMenuFirstLineDiv--2A80o";
export var burgerMenuStartLearningLink = "NavBar-module--burgerMenuStartLearningLink--2W5iZ";
export var burgerMenuCloseButtonDiv = "NavBar-module--burgerMenuCloseButtonDiv--Tcuxm";
export var burgerMenuContentDiv = "NavBar-module--burgerMenuContentDiv--28njV";
export var burgerMenuCloseButtonSvg = "NavBar-module--burgerMenuCloseButtonSvg--2b66_";
export var burgerMenuCloseButtonPath = "NavBar-module--burgerMenuCloseButtonPath--3by32";
export var burgerSearchMainDiv = "NavBar-module--burgerSearchMainDiv--cfBcQ";
export var burgerOptionDiv = "NavBar-module--burgerOptionDiv--3BfDn";
export var optionLogoutButton = "NavBar-module--optionLogoutButton--3qKEY";