import { mainContainer, mainDiv, mainDesktopUl, optionLi, optionLink, logoDiv, logoImg, 
  mainOptionsDiv, searchIconImg, recetasDropdown, recetasDropdownContent, linkBold, searchIconDiv,
  searchMainDiv, topMainDiv, searchDiv, searchInput, searchButton, searchGlass, searchForm,
  mainContainerOpened, optionLoginLink, optionCoursesLink, mainMobileUl, optionBurgerIconSvg, 
  optionBurgerIconPath, burgerMenuMainDiv, burgerLogoImg, burgerMenuFirstLineDiv, burgerMenuStartLearningLink,
  burgerMenuCloseButtonDiv, burgerMenuContentDiv, burgerMenuCloseButtonSvg, burgerMenuCloseButtonPath,
  burgerSearchMainDiv, burgerOptionDiv, optionLogoutButton, logoA } from './NavBar.module.css'
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import { navigate } from "gatsby"
import useWindowSize from "../utils/useWindowSize";
import userContext from "../utils/userContext"

const NavBar = ({updateOnKeyPress, showFixedSearch}) => {
  const [searchInputPlaceHolder, setSearchInputPlaceHolder] = React.useState(null);
  const [searchOpened, setSearchOpened] = React.useState(showFixedSearch ? true : false);
  const [burgerMenuOpened, setBurgerMenuOpened] = React.useState(false);
  const [isUserLogValid, setIsUserLogValid] = React.useState(false);
  const [startLearningUrl, setStartLearningUrl] = React.useState('/landing/curso-tortas-de-cumpleanos/');
  const { width } = useWindowSize();

  const data = useStaticQuery(graphql`
  {
    allMysqlAllCategories(
      filter: {competitionValue: {lt: 19}, avgMonthlySearches: {gt: 1000}}
      sort: {order: DESC, fields: avgMonthlySearches}
    ) {
      nodes {
        title,
        pageUrl
      }
    }
  }
  `)

  useEffect(() => {
    if (width > 500) {
        setSearchInputPlaceHolder('Busca por receta, ingrediente, plato...');
    } else {
        setSearchInputPlaceHolder('Busca por receta...');
    }
  }, [width])

  const handleSubmit = event => {
    event.preventDefault();
    if (state.search) {
        navigate(`/busqueda-receta?search=${state.search}`);
    } else {
        
    }
  }

  const handleSearchInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    setState({
        [name]: value,
    })
    
    if (updateOnKeyPress) {
      navigate(`/busqueda-receta?search=${target.value}`);
    }
  }

  const [state, setState] = useState({
      search: '',
  });

  function openSearch() {
    setSearchOpened(!searchOpened);
  }

  function closeBurgerMenu() {
    setBurgerMenuOpened(false);
  }

  function openBurgerMenu() {
    setBurgerMenuOpened(true);
  }

  function logout() {
    userContext.logout();
    setIsUserLogValid(false);
  }

  useEffect(() => {
    if (userContext.isLogged()) {
      userContext.loadUser().then(resultStatus => {
        if (resultStatus == "OK") {
          setIsUserLogValid(true);
          if (userContext.isSuscribedToCourse(userContext.user.lastCourseId)) {
            setStartLearningUrl('curso/' + userContext.user.lastCourse);
          }
        } else {
          setIsUserLogValid(false);
        }
      });
    }
  }, [])

  return (
    <nav className={searchOpened ? mainContainerOpened : mainContainer}>
      { burgerMenuOpened ?
        <div className={burgerMenuMainDiv}>
          <div className={burgerMenuFirstLineDiv}>
            <StaticImage className={burgerLogoImg} src="../images/logoTextOnly.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
            <Link className={burgerMenuStartLearningLink} to={startLearningUrl}> Comenza a Aprender </Link>
            <div className={burgerMenuCloseButtonDiv} onClick={() => closeBurgerMenu()}>
              <svg className={burgerMenuCloseButtonSvg} width="32px" height="32px" viewBox="0 0 24 24"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path className={burgerMenuCloseButtonPath} d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
            </div>
          </div>
          <div className={burgerMenuContentDiv}>
            {!showFixedSearch ?
              <div className={burgerSearchMainDiv}>
                <form className={searchForm} onSubmit={handleSubmit}>
                    <div className={searchDiv}>
                        <div className={searchGlass}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0z" stroke="currentColor"></path><path d="m15 15 5 5" stroke="currentColor"></path></svg></div>
                        <input className={searchInput} name="search" value={state.search} onChange={handleSearchInputChange} placeholder={searchInputPlaceHolder}></input>
                        <button className={searchButton} type="submit">Buscar</button>
                    </div>
                </form>
              </div>
              :
              <></>
            }
            <div className={burgerOptionDiv}>
              <Link className={optionLink} to="/todos-los-articulos/"> Trucos </Link>
            </div>
            <div className={burgerOptionDiv}>
              <Link className={optionLink} to="/todas-las-recetas/"> Recetas </Link>
            </div>
            <div className={burgerOptionDiv}>
              {isUserLogValid ?
                <button className={optionLogoutButton} onClick={() => logout()}> Salir </button>
              :
                <Link className={optionLoginLink} to="/login/"> Entrar </Link>
              }
            </div>
          </div>
        </div>
        :
        <></>
      }
      <div className={mainDiv}>
        <div className={topMainDiv}>
          <div className={logoDiv}>
            <a className={logoA} href="/" aria-label="Home">
              <StaticImage className={logoImg} src="../images/logoTextOnly.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
            </a>
          </div>
          <div className={mainOptionsDiv}>
            <ul className={mainDesktopUl}>
              <li className={optionLi}>
                {isUserLogValid ?
                  <button className={optionLogoutButton} onClick={() => logout()}> Salir </button>
                :
                  <Link className={optionLoginLink} to="/login/"> Entrar </Link>
                }
              </li>
              <li className={optionLi}>
                <Link className={optionCoursesLink} to={startLearningUrl}> Comenza a Aprender </Link>
              </li>
              <li className={optionLi}>
                <Link className={optionLink} to="/todos-los-articulos/"> Trucos </Link>
              </li>
              <li className={optionLi}>
                <div className={recetasDropdown}>
                  <Link className={optionLink} to="/"> Recetas </Link>
                  <div className={recetasDropdownContent}>
                    <Link className={linkBold} to='/recetas-de-postres/'>Recetas de Postres</Link>
                  { data.allMysqlAllCategories.nodes.map((item) => {
                    return (
                      <Link to={'/recetas/' + item.pageUrl + '/'}>{item.title}</Link>
                    )
                  })
                  }<Link className={linkBold} to='/todas-las-recetas/'>Todas las Recetas</Link>
                  </div>
                </div>
              </li>
              <div className={searchIconDiv}  onClick={() => openSearch()}>
                <StaticImage className={searchIconImg} width={20} src="../images/searchIcon.png" imgStyle={{objectFit: 'contain'}} alt="Fede Cocina"/>
              </div>
            </ul>
            <ul className={mainMobileUl}>
              <li className={optionLi}>
                <Link className={burgerMenuStartLearningLink} to={startLearningUrl}> Comenza a Aprender </Link>
              </li>
              <li className={optionLi}>
                <svg className={optionBurgerIconSvg} onClick={() => openBurgerMenu()} width="30px" height="30px" viewBox="0 0 48 48">
                  <path className={optionBurgerIconPath} d="M7.94977 11.9498H39.9498" stroke-width="4" stroke-linecap="round"/>
                  <path className={optionBurgerIconPath} d="M7.94977 23.9498H39.9498" stroke-width="4" stroke-linecap="round"/>
                  <path className={optionBurgerIconPath} d="M7.94977 35.9498H39.9498" stroke-width="4" stroke-linecap="round"/>
                </svg>
              </li>
            </ul>
          </div>
        </div>
        { searchOpened ?
          <div className={searchMainDiv}>
            <form className={searchForm} onSubmit={handleSubmit}>
                <div className={searchDiv}>
                    <div className={searchGlass}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0z" stroke="currentColor"></path><path d="m15 15 5 5" stroke="currentColor"></path></svg></div>
                    <input className={searchInput} name="search" value={state.search} onChange={handleSearchInputChange} placeholder={searchInputPlaceHolder}></input>
                    <button className={searchButton} type="submit">Buscar</button>
                </div>
            </form>
          </div>
          :
          <></>
        }
      </div>
    </nav>
  )
}

export default NavBar
